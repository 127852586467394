<template>
	<div>
		<el-card>
			<el-row>
				<el-button type="primary" @click="addLevel">新增经理</el-button>
			</el-row>
			<!-- 经理表单 -->
			<el-table :data="levelData" border style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="agent_id" label="ID"></el-table-column>
				<el-table-column prop="real_name" label="真实名称"></el-table-column>
				<el-table-column prop="account" label="账号"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="phoneNumber" label="手机号" width="120px"></el-table-column>
				<el-table-column label="上岗状态">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.agent_status" @change="setAgentStatus(scope.row)"></el-switch>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="关联用户" width="100px">
					<template slot-scope="scope">
				        <el-tag type="primary" disable-transitions>{{scope.row.relevantUser.nickName}}</el-tag>
				    </template>
				</el-table-column>
				<el-table-column prop="city_name" label="服务区域"></el-table-column>
				<el-table-column prop="add_time" label="注册时间" width="150px"></el-table-column>
				<el-table-column label="操作" fixed="right" width="260px">
					<template slot-scope="scope">
						<el-button type="primary" plain size="small" @click="editLevel(scope.row)">编辑</el-button>
						<!--<el-button type="danger" plain size="small" @click="removeLevel(scope.row)">删除</el-button>-->
						<el-button size="mini" type="success" plain @click="setPwd(scope.row)">重置密码</el-button>
						<el-button size="mini" type="warning" plain @click="changeBz(scope.row)">分佣标准</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 新增/编辑对话框 -->
			<el-dialog :title="title" width="60%" :visible.sync="addLevelDialog" :modal-append-to-body="false">
				<el-form ref="addlevelForm" :model="addlevelForm" label-width="120px" :rules="addlevelRules">
					<el-row>
					  	<el-col :span="12">
							<el-form-item label="真实名称" prop="real_name">
								<el-input v-model="addlevelForm.real_name" maxlength="10" placeholder="请输入真实姓名"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="服务区域" prop="city_id" style="text-align: left;">
								<treeselect v-model="addlevelForm.city_id" :options="city_list" :defaultExpandLevel="Infinity" :normalizer="normalizer" :show-count="true" placeholder="请选择服务区域" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="账号" prop="account">
								<el-input v-model="addlevelForm.account" maxlength="10" placeholder="请输入账号" :disabled="addlevelForm.agent_id?'disabled':false"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="密码" prop="password">
								<el-input v-model="addlevelForm.password" placeholder="请输入密码" :disabled="addlevelForm.agent_id?'disabled':false" style="width:220px"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号" prop="phoneNumber">
								<el-input v-model.number="addlevelForm.phoneNumber" placeholder="请输入手机号"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="关联用户" style="text-align: left;">
								<el-tag style="cursor: pointer;" @click="initUser">
									<div v-if="addlevelForm.relevantUser.nickName">{{addlevelForm.relevantUser.nickName}}&nbsp;&nbsp;&nbsp;&nbsp;{{addlevelForm.relevantUser.phoneNumber}}</div>
									<div v-else>选择关联用户</div>
								</el-tag>
								<el-dialog :append-to-body="true" @close="closeUserVisible" title="关联用户" :visible.sync="userVisible" width="50%" top="5vh" center>
									<el-form :model="userForm" label-width="80px">
										<el-form-item label="输入查询">
											<el-row>
												<el-col :span="12">
													<el-input v-model="userForm.userInfo" placeholder="请输入用户昵称/手机号" clearable></el-input>
												</el-col>
												<el-col :span="12">
													<el-button type="primary" style="margin-left:10px" @click="searchUser">搜索</el-button>
												</el-col>
											</el-row>
										</el-form-item>
									</el-form>
									<el-table highlight-current-row @row-click="rowClick" :data="relevantUserTable" style="width: 100%;margin-top:15px" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
										<el-table-column prop="wx_id" label="用户ID"></el-table-column>
										<el-table-column label="头像">
											<template slot-scope="scope">
												<div>
													<img style="width:60px;height:60px" :src="scope.row.avatarUrl" alt="">
												</div>
											</template>
										</el-table-column>
										<el-table-column prop="nickName" label="昵称"></el-table-column>
										<el-table-column prop="phoneNumber" label="手机号"></el-table-column>
									</el-table>
									<Pagination :queryInfo="queryUserInfo" :total="user_total" @handleSizeChange="handleUserSizeChange" @handleCurrentChange="handleUserCurrentChange"></Pagination>
									<div style="text-align:center">
										<el-button type="default" @click="closeUserVisible">取消</el-button>
										<el-button type="primary" @click="subUser">确定</el-button>
									</div>
								</el-dialog>
							</el-form-item>
						</el-col>
						<el-col :span="24" style="text-align:center">
						  	<el-button type="default" @click="addLevelDialog=false">取消</el-button>
							<el-button type="primary" @click="editNewLevel">确定</el-button>
					  	</el-col>
					</el-row>
				</el-form>
			</el-dialog>
			
			<el-dialog title="修改分佣标准" width="36%" :visible.sync="bzVisible">
				<el-form label-width="80px">
					<el-form-item label="分佣标准:" style="margin-bottom: 20px;">
						<el-select v-model="biaozhun_id" placeholder="请选择">
					    <el-option
					      v-for="item in biaozhun_list"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					  </el-select>
					</el-form-item>
					<el-button type="default" @click="bzVisible=false">取消</el-button>
					<el-button type="primary" @click="subBzEdit">确认</el-button>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Treeselect,Pagination
		},
		data() {
			return {
				title: "新增经理",
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				options: [],
				addLevelDialog: false,
				total: 0,
				addlevelForm: {
					real_name: '',
					account: '',
					password: '',
					parent_id: '',
					city_id: '',
					relevantUser: {},
					agent_type: 2,
					phoneNumber: '',
				},
				addlevelRules: {
					agent_name: [{
						required: true,
						message: "请输入真实名称",
						trigger: "blur"
					}, ],
					account: [{
						required: true,
						message: "请输入账号",
						trigger: "blur"
					}, ],
					phoneNumber: [{
						required: true,
						message: "请输入手机号",
						trigger: "blur"
					}, ],
					city_id: [{
						required: true,
						message: "请选择服务区域",
						trigger: "blur"
					}, ],
				},
				levelData: [],
				userVisible: false,
				userForm: {
					userInfo: ''
				},
				relevantUserTable: [],
				queryUserInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				user_total: 0,
				relevantUserfalse: {},
				loading: false,
				city_list: [],
				//分佣标准
				bzVisible: false,
				relation_id: '',
				biaozhun_id: '',
				biaozhun_list: [],
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getAgentList();
				this.getCityList();
			}
		},
		methods: {
			getCityList(){
		  		var url = 'city/city_list';
				let params = {
					is_del: 1
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.city_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
		  	},
			remoteMethod(query) {
				if (query !== '') {
					that.loading = true;
					setTimeout(() => {
			            that.loading = false;
			            var url = 'agent/parent_agent';
						let params = {
							agent_name: query,
							agent_id: that.addlevelForm.agent_id,
						};
						that.fd_post(url, params).then((res) => {
							if(res.status){
								that.options = res.list;
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 200);
				} else {
					this.options = [];
				}
			},
			getAgentList() {
				let that = this;
				var url = 'agent/agent_list';
				let params = {
					agent_type: 2,
					account: this.queryInfo.query,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.levelData = res.list;
						that.biaozhun_list = res.biaozhun_list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			initUser() {
				this.userVisible = true;
				this.getCustomList();
			},
			getCustomList() {
				var url = 'custom/user_list';
				let params = {
					is_phone: 1,
					nickName: this.userForm.userInfo,
					size: this.queryUserInfo.pagesize,
					page: this.queryUserInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.user_total = res.total;
						that.relevantUserTable = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//转换菜单数据结构
			normalizer(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.city_id,
					label: node.city_name,
					children: node.children,
				};
			},
			//新增经理
			addLevel() {
				this.title = "新增经理";
				this.addLevelDialog = true;
				this.addlevelForm = {
					real_name: '',
					account: '',
					password: '',
					parent_id: '',
					fypb_two: '',
					fypb_three: '',
					relevantUser: {},
					agent_type: 2,
					phoneNumber: '',
				};
			},
			//确认新增
			editNewLevel() {
				console.log(that.addlevelForm);
				this.$refs.addlevelForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null){clearTimeout(timer);}
        				timer = setTimeout(() => {
							var url = 'agent/addOrUpdate';
							if(that.addlevelForm.relevantUser.wx_id) {
								that.addlevelForm.wx_id = that.addlevelForm.relevantUser.wx_id;
							}
							let params = that.addlevelForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.addLevelDialog = false;
									that.$refs.addlevelForm.resetFields();
									that.$message.success('操作成功');
									that.getAgentList();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.$message.error('网络错误');
							});
						},300);
					}
				});
			},
			//打开编辑对话框
			editLevel(levelInfo) {
				this.title = "编辑经理";
				for(let k in levelInfo) {
					this.addlevelForm[k] = levelInfo[k];
				}
				if(this.addlevelForm.parent_id){
					this.remoteMethod(this.addlevelForm.parent_name);
				}
				setTimeout(() => {
					this.addLevelDialog = true;
				},300);
			},
			//设置上岗状态
			setAgentStatus(item){
				if(timer != null){clearTimeout(timer);}
				timer = setTimeout(() => {
					var url = 'agent/setAgentStatus';
		        	let params = {
		        		agent_id: item.agent_id
		        	};
					that.fd_post(url, params).then((res) => {
		        		if(res.status){
		        			that.$message.success("操作成功");
		        		}else{
		        			that.$message.error(res.msg);
		        		}
			        }).catch((err) => {
			            that.$message.error('网络错误');
			        });
				},300);
			},
			//删除
			removeLevel(item) {
				this.$confirm('删除之后，该经理账号下的所有技师将不再属于该经理，技师归属总部，经理的佣金提成也会一并取消。确认删除经理账号吗？', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'agent/deleteAgent';
					let params = {
						agent_id: item.agent_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('删除成功');
							this.getAgentList();
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消删除')
				});
			},
			setPwd(item){
				this.$confirm('您确定要为'+item.real_name+'【'+item.phoneNumber+'】重置密码吗，确定后密码将会重置为【123456】？', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'agent/updatePwd';
					let params = {
						agent_id: item.agent_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('密码重置成功');
							this.getAgentList();
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消重置')
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getAgentList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getAgentList();
			},
			closeUserVisible() {
				this.userVisible = false;
				this.userForm.userInfo = '';
				this.relevantUserfalse = {};
			},
			//页数改变
			handleUserSizeChange(newSize) {
				this.queryUserInfo.pagenum = 1;
				this.queryUserInfo.pagesize = newSize;
				this.getCustomList();
			},
			//页码改变
			handleUserCurrentChange(newPage) {
				this.queryUserInfo.pagenum = newPage;
				this.getCustomList();
			},
			searchUser() {
				this.getCustomList();
			},
			rowClick(row) {
				this.relevantUserfalse = row;
			},
			//关联用户
			subUser() {
				this.addlevelForm.relevantUser = this.relevantUserfalse;
				this.addlevelForm.phoneNumber = this.relevantUserfalse.phoneNumber;
				this.userVisible = false;
			},
			//分佣标准
			changeBz(item){
				this.relation_id = item.agent_id;
				this.biaozhun_id = item.biaozhun_id;
				this.bzVisible = true;
			},
			subBzEdit(){
				var url = 'biaozhun/relation';
		        let params = {
		        	biaozhun_id: this.biaozhun_id,
		        	relation_id: this.relation_id,
		        	relation_type: 1,
		        };
		        this.fd_post(url, params).then((res) => {
	        		if(res.status){
	        			this.bzVisible = false;
	        			this.$message.success("操作成功");
	        			this.getAgentList();
	        		}else{
	        			this.$message.error(res.msg);
	        		}
		        }).catch((err) => {
		            this.$message.error('网络错误');
		        });
			},
		},
	}
</script>

<style lang="scss" scoped>
	.el-row {
		text-align: left;
		margin-bottom: 20px;
	}
	.mr3{
	  margin-right: 5px;
	}
</style>